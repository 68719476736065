export const cities = [
  { name: 'Amsterdam', coordinates: [53, 42] },
  { name: 'Athen', coordinates: [87, 80] },
  { name: 'Barcelona', coordinates: [81, 33] },
  { name: 'Berlin', coordinates: [53, 56] },
  { name: 'Brüssel', coordinates: [56, 40] },
  { name: 'Dublin', coordinates: [47, 24] },
  { name: 'Dresden', coordinates: [56, 56] },
  { name: 'Düsseldorf', coordinates: [57, 46] },
  { name: 'Erfurt', coordinates: [56, 52] },
  { name: 'Frankfurt', coordinates: [58, 48] },
  { name: 'Hamburg', coordinates: [50, 49] },
  { name: 'Helsinki', coordinates: [30, 69] },
  { name: 'Kopenhagen', coordinates: [45, 54] },
  { name: 'Köln', coordinates: [57, 46] },
  { name: 'Leipzig', coordinates: [56, 54] },
  { name: 'London', coordinates: [53, 33] },
  { name: 'Madrid', coordinates: [83, 22] },
  { name: 'München', coordinates: [64, 54] },
  { name: 'Oslo', coordinates: [33, 51] },
  { name: 'Paris', coordinates: [62, 37] },
  { name: 'Prag', coordinates: [60, 58] },
  { name: 'Rom', coordinates: [80, 55] },
  { name: 'Stockholm', coordinates: [34, 61] },
  { name: 'Wien', coordinates: [64, 61] },
  { name: 'Zürich', coordinates: [66, 48] },
];

export const countries = [
  { name: 'DE', coordinates: [57, 52] },
  { name: 'AT', coordinates: [67, 60] },
  { name: 'B', coordinates: [58, 42] },
  { name: 'CZ', coordinates: [61, 61] },
  { name: 'DK', coordinates: [44, 50] },
  { name: 'F', coordinates: [68, 37] },
  { name: 'GR', coordinates: [88, 78] },
  { name: 'IRE', coordinates: [48, 21] },
  { name: 'NL', coordinates: [55, 43] },
  { name: 'NO', coordinates: [31, 50] },
  { name: 'ES', coordinates: [85, 22] },
  { name: 'CH', coordinates: [69, 48] },
  { name: 'UK', coordinates: [48, 32] },
];
export const reviewedCities = [
  'Amsterdam',
  'Athen',
  'Berlin',
  'Brüssel',
  'Dublin',
  'Düsseldorf',
  'Erfurt',
  'Frankfurt',
  'Hamburg',
  'Kopenhagen',
  'Leipzig',
  'London',
  'Madrid',
  'München',
  'Oslo',
  'Paris',
  'Prag',
  'Wien',
  'Zürich',
];

export const reviewedInvestors = [
  { name: 'Fredensborg/Heimstaden', slug: 'fredensborg-heimstaden' },
  //{ name: "Akelius", slug: "akelius" },
  { name: 'Vonovia SE', slug: 'vonovia' },
  { name: 'Grand City Properties', slug: 'grand-city-properties' },
  { name: 'Blackstone', slug: 'blackstone' },
  { name: 'Bayrische Versorgungskammer', slug: 'bayrische-versorgungskammer' },
  { name: 'AXA', slug: 'axa' },
  { name: 'Swisslife', slug: 'swisslife' },
];
